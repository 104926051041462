<template>
<main>
  <div class="style_bg_img" style="background-image: url('assets/img/banner.jpg');">
    <div class="pt-5 pb-3">
      <div class="container">
          <div class="text-center col-sm-10 mx-auto">
              <div class="py-5 text-white">
                  <h1 class="font-weight-light my-4 animated bounce slow">Trouver une Offres</h1>
              </div>
          </div>
          <form class="animated fadeInUp slow">
          <div class="row">
            <div class="col-sm form-group">
              <input type="text" class="form-control form-control-lg style_form_control border-white" name="" placeholder="Offres">
            </div>
            <div class="col-sm form-group">
              <select type="select" class="form-control form-control-lg style_form_control border-white" name="" placeholder="Ville">
                <option>Cotonou</option>
                <option>Cotonou</option>
                <option>Cotonou</option>
                <option>Cotonou</option>
                <option>Cotonou</option>
              </select>
            </div>
            <div class="col-sm form-group">
              <button class="btn btn-block btn-lg style_btn btn-danger">Rechercher</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="bg-light">
    <div class="py-5 container">
      <div class="mb-4 d-flex justify-content-between align-items-center">
        <h4 class="text-dark mb-0"> <i class="fa fa-square-full mr-3 text-yellow animated zoomIn infinite"></i> Nos Offres</h4>
      </div>
      <div class="row">
        <div class="col-sm-6 mb-3">
          <div class="border-bottom border-top rounded-lg bg-transparent h-100 text-center py-3">
            <div class="text-left">
                <div class="my-0 d-flex flex-column">
                  <h6 class="font-weight-bold">
                    <a href="offre_detail.html" class="text-success">Nom de l'offre</a>
                    <small class="text-muted ml-2">12 Fev, 2022</small>
                  </h6>
                  <small>
                    <span class="text-muted">
                      <a href="#">Nom de l'agence</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de l'établissement</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de la ville</a>
                    </span>
                  </small>
                </div>
                <div class="border-top pt-2 mt-2">
                  <p class="text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="border-bottom border-top rounded-lg bg-transparent h-100 text-center py-3">
            <div class="text-left">
                <div class="my-0 d-flex flex-column">
                  <h6 class="font-weight-bold">
                    <a href="offre_detail.html" class="text-success">Nom de l'offre</a>
                    <small class="text-muted ml-2">12 Fev, 2022</small>
                  </h6>
                  <small>
                    <span class="text-muted">
                      <a href="#">Nom de l'agence</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de l'établissement</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de la ville</a>
                    </span>
                  </small>
                </div>
                <div class="border-top pt-2 mt-2">
                  <p class="text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="border-bottom border-top rounded-lg bg-transparent h-100 text-center py-3">
            <div class="text-left">
                <div class="my-0 d-flex flex-column">
                  <h6 class="font-weight-bold">
                    <a href="offre_detail.html" class="text-success">Nom de l'offre</a>
                    <small class="text-muted ml-2">12 Fev, 2022</small>
                  </h6>
                  <small>
                    <span class="text-muted">
                      <a href="#">Nom de l'agence</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de l'établissement</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de la ville</a>
                    </span>
                  </small>
                </div>
                <div class="border-top pt-2 mt-2">
                  <p class="text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="border-bottom border-top rounded-lg bg-transparent h-100 text-center py-3">
            <div class="text-left">
                <div class="my-0 d-flex flex-column">
                  <h6 class="font-weight-bold">
                    <a href="offre_detail.html" class="text-success">Nom de l'offre</a>
                    <small class="text-muted ml-2">12 Fev, 2022</small>
                  </h6>
                  <small>
                    <span class="text-muted">
                      <a href="#">Nom de l'agence</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de l'établissement</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de la ville</a>
                    </span>
                  </small>
                </div>
                <div class="border-top pt-2 mt-2">
                  <p class="text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="border-bottom border-top rounded-lg bg-transparent h-100 text-center py-3">
            <div class="text-left">
                <div class="my-0 d-flex flex-column">
                  <h6 class="font-weight-bold">
                    <a href="offre_detail.html" class="text-success">Nom de l'offre</a>
                    <small class="text-muted ml-2">12 Fev, 2022</small>
                  </h6>
                  <small>
                    <span class="text-muted">
                      <a href="#">Nom de l'agence</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de l'établissement</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de la ville</a>
                    </span>
                  </small>
                </div>
                <div class="border-top pt-2 mt-2">
                  <p class="text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="border-bottom border-top rounded-lg bg-transparent h-100 text-center py-3">
            <div class="text-left">
                <div class="my-0 d-flex flex-column">
                  <h6 class="font-weight-bold">
                    <a href="offre_detail.html" class="text-success">Nom de l'offre</a>
                    <small class="text-muted ml-2">12 Fev, 2022</small>
                  </h6>
                  <small>
                    <span class="text-muted">
                      <a href="#">Nom de l'agence</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de l'établissement</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de la ville</a>
                    </span>
                  </small>
                </div>
                <div class="border-top pt-2 mt-2">
                  <p class="text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="border-bottom border-top rounded-lg bg-transparent h-100 text-center py-3">
            <div class="text-left">
                <div class="my-0 d-flex flex-column">
                  <h6 class="font-weight-bold">
                    <a href="offre_detail.html" class="text-success">Nom de l'offre</a>
                    <small class="text-muted ml-2">12 Fev, 2022</small>
                  </h6>
                  <small>
                    <span class="text-muted">
                      <a href="#">Nom de l'agence</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de l'établissement</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de la ville</a>
                    </span>
                  </small>
                </div>
                <div class="border-top pt-2 mt-2">
                  <p class="text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="border-bottom border-top rounded-lg bg-transparent h-100 text-center py-3">
            <div class="text-left">
                <div class="my-0 d-flex flex-column">
                  <h6 class="font-weight-bold">
                    <a href="offre_detail.html" class="text-success">Nom de l'offre</a>
                    <small class="text-muted ml-2">12 Fev, 2022</small>
                  </h6>
                  <small>
                    <span class="text-muted">
                      <a href="#">Nom de l'agence</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de l'établissement</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de la ville</a>
                    </span>
                  </small>
                </div>
                <div class="border-top pt-2 mt-2">
                  <p class="text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="border-bottom border-top rounded-lg bg-transparent h-100 text-center py-3">
            <div class="text-left">
                <div class="my-0 d-flex flex-column">
                  <h6 class="font-weight-bold">
                    <a href="offre_detail.html" class="text-success">Nom de l'offre</a>
                    <small class="text-muted ml-2">12 Fev, 2022</small>
                  </h6>
                  <small>
                    <span class="text-muted">
                      <a href="#">Nom de l'agence</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de l'établissement</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de la ville</a>
                    </span>
                  </small>
                </div>
                <div class="border-top pt-2 mt-2">
                  <p class="text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="border-bottom border-top rounded-lg bg-transparent h-100 text-center py-3">
            <div class="text-left">
                <div class="my-0 d-flex flex-column">
                  <h6 class="font-weight-bold">
                    <a href="offre_detail.html" class="text-success">Nom de l'offre</a>
                    <small class="text-muted ml-2">12 Fev, 2022</small>
                  </h6>
                  <small>
                    <span class="text-muted">
                      <a href="#">Nom de l'agence</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de l'établissement</a>
                    </span>
                    <span class="mx-2 text-success">|</span>
                    <span class="text-muted">
                      <a href="#">Nom de la ville</a>
                    </span>
                  </small>
                </div>
                <div class="border-top pt-2 mt-2">
                  <p class="text-muted mb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <nav class="mt-3">
        <ul class="pagination justify-content-center">
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</main>
 
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: 'Home',
  components: {
    // HelloWorld,
  },
}
</script>
